<template>
<div>
      <b-skeleton-table v-if="loading" :columns="2" :rows="8"></b-skeleton-table>
      <b-container v-else-if="tournament">
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Status</b-col>
                  <b-col>
                        <b-select v-model="tournament.status">
                              <b-select-option value="0">Skapad</b-select-option>
                              <b-select-option value="1">Ej Godkänd</b-select-option>
                              <b-select-option value="2">Godkänd</b-select-option>
                              <b-select-option value="3">Spärrad</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Aktiv?</b-col>
                  <b-col>
                        <b-select v-model="tournament.active">
                              <b-select-option value="0">Ej startad</b-select-option>
                              <b-select-option value="1">Öppen</b-select-option>
                              <b-select-option value="2">Avslutad</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Namn</b-col>
                  <b-col>
                        <b-input v-model="tournament.name" placeholder="Ange namn..."></b-input>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Öppnar</b-col>
                  <b-col>
                        <b-form-datepicker v-model="tournament.opendate" placeholder="Datum då användare får ansluta..."></b-form-datepicker>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Startar</b-col>
                  <b-col>
                        <b-form-datepicker v-model="tournament.startdate" placeholder="Datum för första matchen..."></b-form-datepicker>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Beskrivning</b-col>
                  <b-col>
                        <b-textarea v-model="tournament.description" placeholder="Ange beskrivning..."></b-textarea>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Sport</b-col>
                  <b-col>
                        <b-select v-model="tournament.sport">
                              <b-select-option value="1">Fotboll</b-select-option>
                              <b-select-option value="2">Ishockey</b-select-option>
                              <b-select-option value="3">Handboll</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Kön</b-col>
                  <b-col>
                        <b-select v-model="tournament.teamtype">
                              <b-select-option value="0">Herr</b-select-option>
                              <b-select-option value="1">Dam</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>
            <b-row class="mb-1">
                  <b-col class="font-weight-bold">Ålder</b-col>
                  <b-col>
                        <b-select v-model="tournament.teamage">
                              <b-select-option value="0">Senior</b-select-option>
                              <b-select-option value="1">Junior</b-select-option>
                        </b-select>
                  </b-col>
            </b-row>

            <b-row class="mt-2" v-if="haserror">
                  <b-col>
                        <Alert variant="danger" v-for="(err, index) in terrors" :key="index" >{{err.error}}</Alert>
                  </b-col>
            </b-row>

            <hr />

            <b-row class="mb-1 mt-2">
                  <b-col class="text-center">
                        <b-button class="mx-1" v-if="!hidecancel" @click="Cancel()" variant="secondary">Avbryt</b-button>
                        <b-button class="mx-1" @click="AddTournament()" variant="primary">Spara</b-button>
                  </b-col>
            </b-row>
      </b-container>
</div>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
export default {
      Name: "AddEditTournament",
      props: {
            "tournament": Object,
            "hidecancel": Boolean
      },
      components: {
            Alert
      },
      data() {
            return {
                  terrors: [],
            }
      },
      computed: {
            loading() {
                  return this.$store.getters["administratorpost/tournamentloading"];
            },
            haserror() {
                  return this.$store.getters["administratorpost/tournamenterror"];
            }
      },
      methods: {
            AddTournament() {
                  this.$store.dispatch("administratorpost/AddTournament", this.tournament).then((response) => {
                        if (response.status == "ok") {
                              this.$emit("saved");
                        } else {
                              this.terrors = response.errors;
                        }
                  });
            },
            Cancel() {
                  this.$emit("cancel");
            }
      },
};
</script>
